import {Injectable} from '@angular/core';
import {_Service} from './_service';
import {
  AppId,
  Company,
  CompanySite,
  Document,
  HasId,
  HasIdName,
  Page,
  Product,
  ProductChain,
  Report
} from '../../model';
import {buildQuery} from '../../util';
import {Params} from '@angular/router';
import {AppState} from '../app-state';

export interface StripeSubscription {
  id: string;
  status: string;
  price: string;
  cancelAt?: Date;
}

@Injectable({providedIn: 'root'})
export class CompanyService extends _Service<Company> {
  constructor(private appState: AppState) {
    super('api/company');
  }

  override list = async (queryParams: Params) =>
    this.http.get<Page<Company>>('api/admin/company' + buildQuery(queryParams));
  reportsForCompany = async (id: string, queryParams: Params) =>
    this.http.get<Page<Report>>(`api/company/${id}/report` + buildQuery(queryParams));
  documentListForCompany = async (companyId: AppId, queryParams: Params) =>
    this.http.get<Page<Document>>(`api/company/${companyId}/document` + buildQuery(queryParams));
  documentListForSite = async (siteId: AppId, queryParams: Params) =>
    this.http.get<Page<Document>>(`api/site/${siteId}/document` + buildQuery(queryParams));
  reportArchiveListForSite = async (siteId: AppId, queryParams: Params) =>
    this.http.get<Page<Document>>(`api/site/${siteId}/archive` + buildQuery(queryParams));
  chainListForSite = async (id: string, queryParams: Params) =>
    this.http.get<Page<Product>>(`api/site/${id}/chain` + buildQuery(queryParams));
  chainsForSite = async (id: string) =>
    this.http.get<Product>(`api/site/${id}/chain/all`);
  chain = async (productId: string) =>
    this.http.get<Product>(`api/chain/${productId}`);
  chainEntries = async (productId: string) =>
    this.http.get<ProductChain[]>(`api/chain/${productId}/entries`);
  chainsForCompany = async (id: string) =>
    this.http.get<Page<Product>>(`api/company/${id}/chain/all`);
  findCompaniesByName = async (q: string) =>
    this.http.get<HasIdName[]>(`api/company/find?q=${encodeURIComponent(q)}`);
  listAll = async () => {
    const companies = await this.http.get<Company[]>('api/company/all');
    this.appState.setCompanies(companies);
    return companies;
  };
  addProduct = async (site: CompanySite, product: Product) =>
    this.http.post<Product>(`api/site/${site.id}/chain`, product);
  updateProduct = async (id: AppId, data: { product: Product }) =>
    this.http.put<Product>(`api/chain/${id}`, data);
  deleteChain = async (chain: ProductChain | Product) =>
    this.http.delete<any>(`api/chain/${chain.id}`);
  createSite = async (company: Company, site: CompanySite) =>
    this.http.post<HasIdName>(`api/company/${company.id}/site`, site);
  updateSite = async (id: AppId, site: CompanySite) =>
    this.http.put<HasIdName>(`api/site/${id}`, site);
  stripeLink = async (companyId: AppId, returnUrl: string) =>
    this.http.post<{ link: string }>(`api/company/${companyId}/stripe`, {returnUrl});
  stripePay = async (companyId: AppId, plan: string, returnUrl: string) =>
    this.http.post<{ link: string }>(`api/company/${companyId}/stripe/create-subscription`,
      {plan, returnUrl});
  stripeSubs = async (companyId: AppId) =>
    this.http.get<StripeSubscription[]>(`api/company/${companyId}/stripe/subscription`);
  updateOwner = async (companyId: AppId, owner: HasId) =>
    this.http.put<void>(`api/company/${companyId}/owner`, owner);
}
