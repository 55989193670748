import {BehaviorSubject, Subject} from 'rxjs';
import {Injectable, Injector} from '@angular/core';
import {Company, CompanySite} from '../model';
import {CompanyService} from './service/company.service';
import {AppAuthService} from './auth';
import {filter} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class AppState {
  companySite$ = new Subject<{ company?: Company, site?: CompanySite }>();
  companies$ = new Subject<Company[]>();
  wideMode$ = new BehaviorSubject<boolean>(false);
  helpMode$ = new BehaviorSubject<boolean>(false);

  constructor(private injector: Injector, authService: AppAuthService, route: ActivatedRoute, router: Router) {
    router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe(() => {
        const {wideMode, helpMode} = AppState.firstRoute(route).snapshot.data;
        this.wideMode$.next(wideMode);
        this.helpMode$.next(helpMode);
      });
    authService.isLoggedIn$.subscribe(async (loggedIn) => {
      if (loggedIn) {
        await this.refresh();
      } else {
        this.setCompanies([]);
        this.selectCompanySite(undefined, undefined);
      }
    });
  }

  private static firstRoute = (route: ActivatedRoute): ActivatedRoute =>
    route.firstChild ? AppState.firstRoute(route.firstChild) : route;
  setCompanies = (companies: Company[]) => this.companies$.next(companies);
  selectCompanySite = (company: Company | undefined, site: CompanySite | undefined) =>
    this.companySite$.next({company, site});
  refresh = async () => await this.injector.get(CompanyService).listAll();
}
